import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { importInternalRider } from 'utils/network/apis';
import { CountrySelect, HubsSelect } from 'partials/select';
import {
  ALPHA_REGEX,
  BIRTHDAY_MASKED_DATE_OPTIONS,
  BIRTHDAY_REGEX,
  getErrorMessage,
  PHONE_NUMBER_REGEX,
  zeroPad,
} from 'utils/helpers';
import {
  Button,
  ButtonVariant,
  Icon,
  Input,
  InputVariant,
  MASKED_DATE_OPTIONS,
  Modal,
  SelectVariant,
} from '@hubportal/components';
import { Info } from 'components/info';
import { Account } from 'models';

import omit from 'lodash.omit';
import * as Yup from 'yup';

type EditableRiderFields = {
  name: string;
  surname: string;
  phone: string;
  email: string;
  hub: { id: string; label: string };
  country: { id: string; label: string };
  birthday: string;
  source: string;
  auth0Id?: string;
};

const initialValues: EditableRiderFields = {
  name: '',
  surname: '',
  phone: '',
  email: '',
  hub: { id: '', label: '' },
  country: { id: '', label: '' },
  birthday: '',
  source: '',
  auth0Id: '',
};

const getValidationSchema = (t): object =>
  Yup.object({
    name: Yup.string()

      .matches(ALPHA_REGEX, t('error_validation_alpha'))
      .required(t('required')),
    surname: Yup.string()
      .matches(ALPHA_REGEX, t('error_validation_alpha'))
      .required(t('required')),
    email: Yup.string().email(t('invalid_email')).required(t('required')),
    phone: Yup.string().matches(
      RegExp(PHONE_NUMBER_REGEX),
      t('invalid_phone_number')
    ),
    hub: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    country: Yup.object({
      id: Yup.string().required(t('required')),
      label: Yup.string(),
    }).required(),
    birthday: Yup.string()
      .matches(RegExp(BIRTHDAY_REGEX), t('invalid_date'))
      .when('country', {
        is: ({ label }: { label: string }) => label === 'NL',
        then: (schema) => schema.required(t('required')),
      }),
  });

export const SingleAccountView = ({
  open,
  ecID,
  account,
  onSuccess,
  onClose,
}: {
  open: boolean;
  ecID: string;
  account: Account;
  onSuccess: () => void;
  onClose: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validateOnMount: false,
    validationSchema: getValidationSchema(t),
    onSubmit: async () => {
      try {
        formik.setSubmitting(true);
        setError(null);

        await importInternalRider({
          first_name: formik.values.name,
          last_name: formik.values.surname,
          email: formik.values.email,
          phone_number: formik.values.phone,
          hub_slug: formik.values.hub.id?.toLowerCase(),
          country: formik.values.country.id?.toUpperCase(),
          date_of_birth: formik.values.birthday,
          source: formik.values.source,
          ec_id: account.ecID || ecID,
        });

        onSuccess();
      } catch (err: any) {
        setError(getErrorMessage(t, err));
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const hub = account?.hubSlug || '';
    const country = hub.slice(0, 2);

    formik.setValues(
      {
        ...formik.values,
        name: account?.firstName || '',
        surname: account?.lastName || '',
        phone: account?.cellPhone || '',
        email: account?.email || '',
        hub: { id: hub || '', label: hub || '' },
        country: {
          id: country.toLowerCase(),
          label: t(`countries_${country.toLowerCase()}`),
        },
        birthday: account?.dateOfBirth || '',
        source: account?.source || '',
        auth0Id: account?.auth0Id || '',
      },
      true
    );
  }, [account]);

  const handleClose = (): void => {
    if (!formik.isSubmitting) {
      setError(null);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      header={
        <div className="title-s max-w-2xl">
          {t('import_internal_rider_confirmation')}
        </div>
      }
    >
      <>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="flex flex-col grow gap-4 min-h-0 overflow-auto max-w-md"
        >
          <div>
            {t('ec_id')}: {zeroPad(ecID, 8)}
          </div>

          {account?.message && (
            <div className="detail-l text-gray-300 flex items-center gap-1 mt-1">
              <div className="text-pink flex items-center justify-center">
                <Icon type="infoSquare" size="small" />
              </div>
              <div>{t(`error_${account?.message?.toLowerCase() || ''}`)}</div>
            </div>
          )}

          <div className="flex gap-2">
            <Input
              required
              label={t('first_name')}
              placeholder={t('first_name')}
              variant={InputVariant.secondary}
              error={formik.errors.name || ''}
              {...formik.getFieldProps('name')}
            />

            <Input
              required
              label={t('last_name')}
              placeholder={t('last_name')}
              variant={InputVariant.secondary}
              error={formik.errors.surname || ''}
              {...formik.getFieldProps('surname')}
            />
          </div>
          <HubsSelect
            variant={SelectVariant.secondary}
            error={formik.touched?.hub ? formik.errors?.hub?.id : ''}
            onChange={(value) => {
              const country = value?.id.toLowerCase().slice(0, 2);
              formik.handleChange({ target: { name: 'hub', value } });
              formik.handleChange({
                target: {
                  name: 'country',
                  value: {
                    id: country.toLowerCase(),
                    label: t(`countries_${country.toLowerCase()}`),
                  },
                },
              });
            }}
            {...omit(formik.getFieldProps('hub'), 'onChange')}
          />
          <CountrySelect
            disabled
            variant={SelectVariant.secondary}
            error={formik.touched.country ? formik.errors.country?.id : ''}
            onChange={(value) =>
              formik.handleChange({ target: { name: 'country', value } })
            }
            {...omit(formik.getFieldProps('country'), 'onChange')}
          />
          <Input
            required
            type="email"
            label={t('email')}
            placeholder="max@gmail.com"
            variant={InputVariant.secondary}
            error={(formik.errors.email as string) || ''}
            {...formik.getFieldProps('email')}
          />

          <Input
            type="tel"
            label={t('phone_number')}
            placeholder="+491112223344"
            variant={InputVariant.secondary}
            error={(formik.errors.phone as string) || ''}
            {...formik.getFieldProps('phone')}
          />

          <Input
            required={formik.values.country.label === 'NL'}
            label={t('date_of_birth')}
            placeholder="2001-08-31"
            maskOptions={{
              ...MASKED_DATE_OPTIONS,
              ...BIRTHDAY_MASKED_DATE_OPTIONS,
            }}
            variant={InputVariant.secondary}
            error={formik.touched.birthday ? formik.errors.birthday : ''}
            {...formik.getFieldProps('birthday')}
          />
        </form>
        {error && (
          <Info className="!mt-5" type="error">
            {error}
          </Info>
        )}
        <div className="flex gap-2 justify-end mt-5">
          <Button
            variant={ButtonVariant.quinary}
            onClick={handleClose}
            disabled={formik.isSubmitting}
          >
            {t('cancel')}
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={
              !formik.isValid ||
              formik.isSubmitting ||
              (account?.message
                ? [
                    'CANT_REPLACE_EXTERNAL_FROM_QX',
                    'CANT_REPLACE_INTERNAL_FROM_QX',
                  ].includes(account?.message)
                : false)
            }
            onClick={() => {
              formik.submitForm();
            }}
          >
            {t('confirm')}
          </Button>
        </div>
      </>
    </Modal>
  );
};
