import { Button, Input, InputType } from '@hubportal/components';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EditableProfileItemProps = {
  type?: InputType;
  label: string;
  field: string;
  value?: string;
  validationSchema: object;
  required?: boolean;
  onChange: (value: string) => Promise<void>;
};

const EditableProfileItem = ({
  type = 'text',
  label,
  field,
  value,
  validationSchema,
  required = true,
  onChange,
}: EditableProfileItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: { [field]: value || '' },
    validationSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);
      try {
        setError(null);
        await onChange(formik.values[field]);
        formik.resetForm({ values: { [field]: formik.values[field] } });
      } catch (err: any) {
        setError(err?.response?.data?.error || err?.message);
      }
      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.resetForm({ values: { [field]: value || '' } });
  }, [value]);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="flex items-center gap-2"
      >
        <Input
          type={type}
          required={required}
          label={label}
          placeholder=" "
          error={formik.touched[field] ? (formik.errors[field] as string) : ''}
          {...formik.getFieldProps(field)}
        />
        <Button
          type="submit"
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          loading={formik.isSubmitting}
        >
          {t('save')}
        </Button>
      </form>
      {error && <span className="text-pink text-s">{error}</span>}
    </div>
  );
};

export { EditableProfileItem };
