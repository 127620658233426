import { Icon } from '@hubportal/components';
import { Link } from 'react-router-dom';

interface Metric {
  title: string;
  value: number | string;
  link: string;
}

interface MetricCardProps {
  mainMetric: Metric;
  otherMetrics: Metric[];
  urgentCheckCount?: number;
  hasRider?: boolean;
  setInfoMoreModal?: () => void;
  setRiderInfoMoreModalClose?: () => void;
  title?: string;
}

export function MetricCard({
  mainMetric,
  otherMetrics,
  hasRider,
  setInfoMoreModal,
  title,
}: MetricCardProps): JSX.Element {
  return (
    <div className="h-full">
      <div
        className={`flex flex-col h-full justify-start
          ${hasRider ? 'rounded-l-lg' : 'rounded-lg'}
        `}
      >
        <div className="p-[1.11vw] justify-center text-white font-medium">
          <div className="flex text-[#BFBFBF] items-center">
            <span className="mr-[0.28vw] subheading">{title}</span>
            <Icon
              type="infoCircle"
              className="w-[1.35vw] h-[1.35vw]"
              onClick={() => setInfoMoreModal && setInfoMoreModal()}
            />
          </div>
          <Link
            to={mainMetric.link}
            reloadDocument
            className="flex justify-center flex-col items-left my-[0.83vw] p-[0.56vw]"
          >
            <div className="font-bold headline-value">{mainMetric.value}</div>
            <div className="mt-[0.42vw] headline-title">{mainMetric.title}</div>
          </Link>

          <div className="grid grid-cols-2 gap-[1vw]">
            {otherMetrics.map((metric, i) => {
              const MetricContent = (
                <div className="p-[0.56vw]">
                  <div className="font-bold subheadline-value">
                    {metric.value}
                  </div>
                  <div className="mt-[0.42vw] text-[#BFBFBF] font-normal subheadline-title">
                    {metric.title}
                  </div>
                </div>
              );

              return metric.link ? (
                <Link key={i} to={metric.link} reloadDocument>
                  {MetricContent}
                </Link>
              ) : (
                <div key={i}>{MetricContent}</div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
