import { useEffect } from 'react';
import { Icon } from '@hubportal/components';
import useStore from 'utils/store';
import { TooltipHeader } from 'components/TooltipHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FullScreen = () => {
  const { t } = useTranslation();
  const { isFullScreen } = useStore();
  const navigate = useNavigate();

  const fullScreenClickHandler = () => {
    // Reference
    // https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API#examples
    if (!document.fullscreenElement) {
      navigate('/activity');
    } else {
      navigate('/activity/tv-mode');
    }
  };

  const onClick = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      navigate('/activity/tv-mode');
    }
  };

  useEffect(() => {
    document.addEventListener(
      'fullscreenchange',
      fullScreenClickHandler,
      false
    );
    return () => {
      document.removeEventListener(
        'fullscreenchange',
        fullScreenClickHandler,
        false
      );
    };
  }, []);
  return (
    <div
      onClick={onClick}
      className="flex justify-center items-center hover:cursor-pointer"
    >
      <span className="text-[#BFBFBF] mr-[0.07vw] ml-[0.18vw] bg-primary p-[0.4vw] rounded-lg">
        {!isFullScreen && (
          <TooltipHeader label="" info={t('fullscreen_enter')}>
            <Icon type="tvView" className="w-[1.5vw] h-[1.5vw]" />
          </TooltipHeader>
        )}
      </span>
    </div>
  );
};

export default FullScreen;
