import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';

import client from './client/module';
import Dashboard from 'pages/Dashboard/Dashboard';
import { colors, sizes, typography, mixins } from 'theme/index';

export const App = ({ authClient }: PropsWithAuth0Client) => (
  <ApolloProvider client={client()}>
    <ThemeProvider theme={{ colors, sizes, typography, mixins }}>
      <div id="orders-module" className="w-full">
        <div className="min-h-screen bg-flinkGray-medium">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard authClient={authClient} />}>
                <Route path="orders" element={<Dashboard authClient={authClient} />} />
                <Route path="orders/tv-mode" element={<Dashboard authClient={authClient} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </ThemeProvider>
  </ApolloProvider>
);
