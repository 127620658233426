import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type TooltipSectionItemProps = {
  titleKey: string;
  descriptionKey: string;
};

const TooltipSectionItem: FC<TooltipSectionItemProps> = ({
  titleKey,
  descriptionKey,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-[1.67vw]">
      <div className="font-medium headline-title mb-[0.28vw]">
        {t(titleKey)}
      </div>
      <div className="font-light text-sm">{t(descriptionKey)}</div>
    </div>
  );
};

export default TooltipSectionItem;
