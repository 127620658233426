import { useState } from 'react';
import Parcel from 'single-spa-react/parcel';

const ridersWidgetUrl: string = process.env.REACT_RIDERS_WIDGET_URL || '';

const RidersWidget = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  const [error, setError] = useState(false);

  if (error || !ridersWidgetUrl) {
    return <></>;
  }

  return (
    <Parcel
      wrapClassName="h-full w-full"
      authClient={authClient}
      isLiveSteering={true}
      handleError={() => {
        setError(true);
      }}
      config={async () => {
        const module = await System.import(ridersWidgetUrl);
        return {
          bootstrap: module.bootstrap,
          mount: module.mount,
          unmount: module.unmount,
        };
      }}
      mapEnabled={true}
      titleEnabled={true}
    />
  );
};

export default RidersWidget;
