import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import useStore from 'hooks/useStore';
import { useNavigate } from 'react-router-dom';

const Button = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.sizes[10]};
  right: ${({ theme }) => theme.sizes[10]};
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: ${({ theme }) => theme.sizes[2]};
  background: none;
  border: none;
  z-index: 15;

  span {
    color: ${({ theme }) => theme.colors.WHITE};
    ${({ theme }) => theme.mixins.textSmall()}
  }

  > div {
    background-color: ${({ theme }) => theme.colors.GRAY_MEDIUM};
    padding: ${({ theme }) => theme.sizes[0.5]};
    border-radius: ${({ theme }) => theme.sizes[1]};
  }
`;

const FullScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isFullScreen, toggleFullScreen } = useStore((state) => ({
    isFullScreen: state.isFullScreen,
    toggleFullScreen: state.toggleFullScreen,
  }));

  const fullScreenClickHandler = () => {
    // Reference
    // https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API#examples
    if (!document.fullscreenElement) {
      toggleFullScreen();
    }
  };

  const onClick = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      toggleFullScreen();
      navigate('/orders/tv-mode');
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      navigate('/orders');
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenClickHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', fullScreenClickHandler, false);
    };
  }, []);

  return (
    <Button onClick={onClick}>
      <span>{isFullScreen ? t('fullscreen_close') : t('fullscreen_enter')}</span>
      <div>
        <Icon icon={isFullScreen ? 'fullscreen-close' : 'fullscreen-open'} size={'small'} />
      </div>
    </Button>
  );
};

export default FullScreen;
