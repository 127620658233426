/**
 *
 * TODO:
 * This file contains all the apis for now but it should be splitted by domain in future
 * Responses should have proper types
 *
 *  */

import {
  GetAllRiderLocationsQuery,
  GetHubDetailsQuery,
} from '../../../graphql';
import { fetch } from '..';

export const getHubsList = (): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_HUBS');
};

export const getRidersList = async (hubSlug, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_RIDERS', {
    data,
    interpolate: { hubSlug },
  });
};

export const getWorkforceList = async (data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE', { data });
};

export const getWorkforceListV2 = async (data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_V2', { data });
};

export const getWorkforceSearch = async (data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_SEARCH', { data });
};

export const getWorkforceDetails = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_DETAILS', {
    interpolate: { auth0Id },
  });
};

export const getWorkforceLateness = async (auth0Id, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_LATENESS', {
    data,
    interpolate: { auth0Id },
  });
};

export const getWorkforceUpcomingSchedule = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_UPCOMING_SCHEDULE', {
    interpolate: { auth0Id },
  });
};

export const getHubPerformance = async (hubSlug, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_HUB_PERFORMANCE', {
    data,
    interpolate: { hubSlug },
  });
};

export const createExternalRider = async (data): Promise<any> => {
  return fetch('POST', 'BFF', 'REST', 'CREATE_EXTERNAL_RIDER', { data });
};

export const getInternalRider = async (ecId): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_INTERNAL_RIDER', {
    interpolate: { ecId },
  });
};

export const importInternalRider = async (data, query?: any): Promise<any> => {
  return fetch('POST', 'BFF', 'REST', 'IMPORT_INTERNAL_RIDER', {
    ...(query ? { query } : null),
    data,
  });
};

export const updateHub = async (auth0Id, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_HUB', {
    data,
    interpolate: { auth0Id },
  });
};

export const updateAgency = async (auth0Id, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_AGENCY', {
    data,
    interpolate: { auth0Id },
  });
};

export const updateEmail = (auth0Id, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_EMAIL', {
    data,
    interpolate: { auth0Id },
  });
};

export const updatePhoneNumber = (auth0Id, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_PHONE', {
    data,
    interpolate: { auth0Id },
  });
};

export const updateRiderStateOffline = async (auth0Id): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_STATE_OFFLINE', {
    data: {},
    interpolate: { auth0Id },
  });
};

export const updateRiderStateTempOffline = async (
  auth0Id,
  data
): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_STATE_TEMP_OFFLINE', {
    data,
    interpolate: { auth0Id },
  });
};

export const updateRiderStateOnline = async (auth0Id): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_STATE_ONLINE', {
    data: {},
    interpolate: { auth0Id },
  });
};

export const block = async (auth0Id): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'BLOCK_RIDER', {
    data: {},
    interpolate: { auth0Id },
  });
};

export const unblock = async (auth0Id): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UNBLOCK_RIDER', {
    data: {},
    interpolate: { auth0Id },
  });
};

export const generatePassword = async (auth0Id): Promise<any> => {
  return fetch('POST', 'BFF', 'REST', 'GENERATE_PASSWORD', {
    interpolate: { auth0Id },
  });
};

export const resetPassword = async (email): Promise<any> => {
  return fetch('POST', 'AUTH0', 'REST', 'PASSWORD_RESET', {
    data: {
      email,
      client_id: process.env.REACT_APP_AUTH0_RIDER_APP_CLIENT_ID,
      connection: 'Username-Password-Authentication',
    },
  });
};

export const getCompliance = async (data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_COMPLIANCE', { data });
};

export const getWorkforceCompliance = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_WORKFORCE_COMPLIANCE', {
    interpolate: { auth0Id },
  });
};

export const getComplianceOpenedCases = async (data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_COMPLIANCE_OPENED_CASES', { data });
};

export const getReliability = async (auth0Id, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_RELIABILITY', {
    data,
    interpolate: { auth0Id },
  });
};

export const getNoShowDays = async (auth0Id, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_NO_SHOWS', {
    data,
    interpolate: { auth0Id },
  });
};

export const getSicknessRate = async (auth0Id, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_SICKNESS_RATE', {
    data,
    interpolate: { auth0Id },
  });
};

export const updateCase = async (auth0Id, caseId, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'UPDATE_CASE', {
    interpolate: { auth0Id, caseId },
    data,
  });
};

export const getActivePunchOverrides = async (ecId): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_ACTIVE_PUNCH_OVERRRIDES', {
    interpolate: { ecId },
  });
};

export const createPunchInOverride = async (data): Promise<any> => {
  return fetch('POST', 'BFF', 'REST', 'CREATE_PUNCH_OVERRIDE', { data });
};

export const getSendouts = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_SENDOUTS', {
    interpolate: { auth0Id },
  });
};

export const getManualWarnings = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_MANUAL_WARNINGS', {
    interpolate: { auth0Id },
  });
};

export const getComments = async (auth0Id): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_COMMENTS', {
    interpolate: { auth0Id },
  });
};

export const createComment = async (auth0Id, data): Promise<any> => {
  return fetch('POST', 'BFF', 'REST', 'CREATE_COMMENT', {
    interpolate: { auth0Id },
    data,
  });
};

export const excuseNoShows = async (auth0Id, data): Promise<any> => {
  return fetch('PATCH', 'BFF', 'REST', 'EXCUSE_NO_SHOWS', {
    interpolate: { auth0Id },
    data,
  });
};

export const getPerformanceGraphData = async (auth0Id, data): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_PERFORMANCE_GRAPH', {
    interpolate: { auth0Id },
    data,
  });
};

export const getPunchImage = async (imageId): Promise<any> => {
  return fetch('GET', 'BFF', 'REST', 'GET_PUNCH_IMAGE', {
    interpolate: { imageId },
  });
};

export const getHubDetails = async (slug: string): Promise<any> => {
  return fetch('POST', 'HUB_ORDERS_BFF', 'GRAPHQL', '', {
    query: GetHubDetailsQuery,
    variables: { slug },
  });
};

export const getAllRiderLocations = async (
  riderIds: string[]
): Promise<any> => {
  return fetch('POST', 'HUB_ORDERS_BFF', 'GRAPHQL', '', {
    query: GetAllRiderLocationsQuery,
    variables: { riderIds },
  });
};
export { fetch };
