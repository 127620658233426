import {
  Badge,
  BadgeSize,
  BadgeVariant,
  Tab,
  Tabs,
  TabsVariant,
} from '@hubportal/components';
import { Sendout } from 'models/sendout';
import { Warning } from 'partials/warning';
import { ManualWarning } from 'partials/warning/manual-warning';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Notes = ({
  warnings = [],
  manualWarnings = [],
}: {
  warnings?: Sendout[];
  manualWarnings?: ManualWarning[];
}): JSX.Element => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('default');

  const tabs = [
    {
      id: 'default',
      label: 'automated_warnings',
      isEnabled: true,
      count: warnings.length,
    },
    {
      id: 'manual',
      label: 'manual_warnings',
      isEnabled: manualWarnings?.length > 0,
      count: manualWarnings?.length,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <div className="text-white header-s">{t('warnings_sent')}</div>
      <div className="flex flex-col rounded-xl bg-primary text-gray-300 p-8  min-h-[14rem]">
        <div className="flex flex-1 flex-col gap-8">
          {manualWarnings?.length > 0 && (
            <Tabs
              value={selectedTab}
              onChange={setSelectedTab as any}
              direction="horizontal"
              variant={TabsVariant.secondary}
            >
              {tabs.map((tab) => (
                <Tab id={tab.id} key={tab.id} disabled={!tab.isEnabled}>
                  <>
                    {t(tab.label)}
                    {tab.count > 0 && (
                      <Badge
                        round="large"
                        size={BadgeSize.small}
                        variant={BadgeVariant.dark}
                      >
                        {tab.count}
                      </Badge>
                    )}
                  </>
                </Tab>
              ))}
            </Tabs>
          )}
          {selectedTab === 'default' && (
            <>
              {warnings?.length === 0 && (
                <div className="grow flex items-center justify-center text-s">
                  {t('warnings_history_empty')}
                </div>
              )}
              {warnings?.length > 0 && (
                <div className="grid grid-cols-[max-content,1fr] gap-x-16 gap-y-2 text-s max-h-96 overflow-auto">
                  {warnings?.map((w, idx) => (
                    <Warning
                      key={idx}
                      item={w}
                      border={idx !== warnings?.length - 1}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          {selectedTab === 'manual' && (
            <>
              {manualWarnings?.length === 0 && (
                <div className="grow flex items-center justify-center text-s">
                  {t('warnings_history_empty')}
                </div>
              )}
              {manualWarnings?.length > 0 && (
                <div className="grid grid-cols-[max-content,1fr] gap-x-16 gap-y-2 text-s max-h-96 overflow-auto">
                  {manualWarnings?.map((w, idx) => (
                    <ManualWarning
                      key={idx}
                      item={w}
                      border={idx !== warnings?.length - 1}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
