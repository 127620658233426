export const Stepper = ({
  children,
  isHubLevelViewActive,
}: {
  children: React.ReactNode;
  isHubLevelViewActive: boolean;
}) => {
  return (
    <div
      className={`flex text-center ${
        isHubLevelViewActive ? 'gap-[1vw] px-[1vw] py-[0.56vw]' : 'gap-4 p-4'
      }`}
    >
      {children}
    </div>
  );
};

const COLOR_PALETTE = {
  pink: 'text-flinkPink-medium',
  blue: 'text-blue-400',
  orange: 'text-orange-400',
  default: 'text-flinkGray-light',
} as const;

export const Step = ({
  count,
  label,
  minimized,
  variant = 'default',
  children,
  isHubLevelViewActive,
  isOutlinedBadgeExist = false,
}: {
  minimized: boolean;
  count: number;
  label: string;
  variant?: keyof typeof COLOR_PALETTE;
  children?: React.ReactNode;
  isHubLevelViewActive: boolean;
  isOutlinedBadgeExist?: boolean;
}) => {
  return (
    <div
      className={`flex [&:not(:last-child):after]:h-px [&:not(:last-child):after]:flex-1 [&:not(:last-child):after]:bg-flinkGray-light [&:not(:last-child):after]:content-[''] [&:not(:last-child)]:flex-1 ${
        isHubLevelViewActive
          ? isOutlinedBadgeExist
            ? '[&:not(:last-child):after]:mt-[2.22vw]'
            : '[&:not(:last-child):after]:mt-[1.11vw]'
          : minimized
          ? '[&:not(:last-child):after]:mt-4'
          : '[&:not(:last-child):after]:mt-6'
      } ${isHubLevelViewActive ? 'gap-x-[1.11vw]' : 'gap-x-4'}`}
    >
      <div
        className={`flex flex-col ${
          isHubLevelViewActive ? 'items-center justify-center gap-y-[0.28vw]' : ' gap-y-2'
        }`}
      >
        <div
          className={`flex ${
            isHubLevelViewActive
              ? 'text-fluid-s flex-row items-center gap-x-[0.07vw] text-left'
              : minimized
              ? 'flex-row items-baseline gap-x-2'
              : 'flex-col items-center gap-x-2'
          } ${COLOR_PALETTE[variant]} `}
        >
          <div
            className={`transition-[font-size] duration-200 ${
              isHubLevelViewActive
                ? 'text-fluid-l mr-[0.56vw] font-bold'
                : minimized
                ? 'header-s'
                : 'header-l'
            } ${variant === 'default' ? 'text-white' : ''}`}
          >
            {count}
          </div>
          <div
            className={
              isHubLevelViewActive
                ? isOutlinedBadgeExist
                  ? 'text-fluid-s text-wrap font-medium'
                  : 'text-fluid-s font-medium'
                : 'title-m'
            }
          >
            {label}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
