import { ExternalProvider, OutsourcingProviderName } from '@hub-orders-bff/schema/types';
import { Maybe } from 'api/generated';
import { RiderStateColors, RiderStates, StateCodesEnum } from 'lib/constants';
import { ListTrip } from 'utils/trips';

export const STATE_FILTERS = [
  StateCodesEnum.PICKERACCEPTED as number,
  StateCodesEnum.PACKED as number,
  StateCodesEnum.ONROUTE as number,
];
export const ALL = 'all_orders';
export const orderFilters = [
  { value: 'ready_for_picking', label: 'ready_for_picking' },
  {
    value: STATE_FILTERS[0],
    label: 'order_state_packing',
  },
  {
    value: STATE_FILTERS[1],
    label: 'order_state_readytodeliver',
  },
  {
    value: STATE_FILTERS[2],
    label: 'order_state_enroute',
  },
  { value: ALL, label: ALL },
];

export const PROVIDER_FILTERS = [
  ExternalProvider.UberEatsCarrefour as string,
  ExternalProvider.Wolt as string,
  ExternalProvider.UberEats as string,
  ExternalProvider.JustEat as string,
  ExternalProvider.JustEatRewe as string,
];

export const PROVIDERS_DELIVERED_INTERNALLY = [
  ExternalProvider.JustEat as string,
  ExternalProvider.JustEatRewe as string,
  ExternalProvider.Unspecified as string,
];

export const providerFilters = [
  { value: 'all', label: 'provider_all' },
  { value: 'internal', label: 'provider_internal' },
  { value: ExternalProvider.UberEatsCarrefour, label: 'carrefour_badge' },
  { value: ExternalProvider.Wolt, label: 'wolt_badge' },
  { value: ExternalProvider.UberEats, label: 'uberEats_badge' },
  { value: ExternalProvider.JustEat, label: 'justEat_badge' },
  { value: ExternalProvider.JustEatRewe, label: 'justEatRewe_badge' },
  { value: OutsourcingProviderName.UberDirect, label: 'uberDirect_badge' },
  { value: OutsourcingProviderName.WoltDrive, label: 'woltDrive_badge' },
];

export const isNullOrUndefined = <T>(value: T | undefined | null): value is null | undefined =>
  value === undefined || value === null;

export const isNotNullNorUndefined = <T>(value: T | undefined | null): value is T =>
  !isNullOrUndefined(value);

// An internal trip is a trip that is delivered by Flink riders regardless of where the order was
// placed. (not outsourced)
// Right now the only active marketplace is JET. However, we keep the validation that the provider
// should be one of these, in case another one appears.
export const isInternalTrip = (trip: ListTrip) => {
  return trip.orders.every(
    (order) => !order.isOutsourced && isProviderInternallyDelivered(order.externalProvider)
  );
};

export const isProviderInternallyDelivered = (
  externalProvider: Maybe<ExternalProvider> | undefined
) => {
  return (
    isNullOrUndefined(externalProvider) || PROVIDERS_DELIVERED_INTERNALLY.includes(externalProvider)
  );
};

export const isJetTrip = (trip: ListTrip) => {
  return trip.orders.every(
    (order) => order.externalProvider === ExternalProvider.JustEat && !order.isOutsourced
  );
};

export const filterByProvider = (provider: string) => (trip: ListTrip) => {
  if (PROVIDER_FILTERS.includes(provider)) {
    return trip.orders.some((order) => order.externalProvider === provider);
  }
  if (provider === OutsourcingProviderName.UberDirect) {
    return trip.orders.some(
      (order) => order.outsourcingProviderName === OutsourcingProviderName.UberDirect
    );
  }
  if (provider === OutsourcingProviderName.WoltDrive) {
    return trip.orders.some(
      (order) => order.outsourcingProviderName === OutsourcingProviderName.WoltDrive
    );
  }
  if (provider === 'internal') {
    return isInternalTrip(trip);
  }
  return true;
};

export const getRiderStateColor = (status: RiderStates, reason?: string): string => {
  if (status === RiderStates.TEMP_OFFLINE && reason) {
    return 'bg-orange-400';
  }
  return RiderStateColors[status] || 'bg-[#ABABAB]';
};

export const getRiderStateLabel = (status: RiderStates, reason?: string): string => {
  if (status === RiderStates.TEMP_OFFLINE) {
    return reason
      ? `rider_state_labels_temp_offline_reason_${reason.toLowerCase()}`
      : 'rider_state_labels_temp_offline_reason_declining';
  }
  return `rider_state_labels_${status.toLowerCase()}`;
};
