import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

export type ActualTrip = {
  __typename?: 'ActualTrip';
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveries?: Maybe<Array<Maybe<ActualTripDelivery>>>;
  hubSlug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  includesStacks?: Maybe<Scalars['Boolean']>;
  riderId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  summaryLine?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActualTripDelivery = {
  __typename?: 'ActualTripDelivery';
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  additionalAddressInfo?: Maybe<Scalars['String']>;
  additionalStreetInfo?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  buildingType?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deliveryNote?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  floorNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  nameOnDoor?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  poBox?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  email?: Maybe<Scalars['String']>;
  isNewCustomer?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export enum ExternalProvider {
  Doordash = 'DOORDASH',
  JustEat = 'JUST_EAT',
  JustEatRewe = 'JUST_EAT_REWE',
  UberEats = 'UBER_EATS',
  UberEatsCarrefour = 'UBER_EATS_CARREFOUR',
  Unspecified = 'UNSPECIFIED',
  Wolt = 'WOLT',
}

export type Hub = {
  __typename?: 'Hub';
  /**
   * CityName is the city name in the language used in `hubs.json`
   * Deprecated: This is not localized and can only be used for debugging related purposes, but
   * not be displayed to the user.
   */
  cityName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /**
   * FulfilmentStartTime is the time at which the fulfilment will start at the hub.
   * This time is only returned if picker and rider shifts haven't started
   * yet (early store opening).
   */
  fulfilmentStartTime?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  /**
   * IsHidden indicates that this hub is hidden and hence not to be exposed
   * anywhere pre-order
   */
  isHidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type HubDetails = {
  __typename?: 'HubDetails';
  coordinates?: Maybe<Location>;
  turfs?: Maybe<Array<Maybe<Array<Maybe<Turf>>>>>;
};

export type HubTrips = {
  __typename?: 'HubTrips';
  bottleneck?: Maybe<Scalars['String']>;
  trips?: Maybe<Array<Maybe<Trip>>>;
};

export type KpiStatistics = {
  __typename?: 'KPIStatistics';
  /**
   * Average fulfillment time in seconds for all orders in the hub
   * (time from order accepted to order delivered)
   */
  averageFulfillmentTime?: Maybe<Scalars['Int']>;
  /**
   * average number of seconds taken to pick each individual order
   * item for all already packed orders
   * (time from order accepted to order packed)
   */
  averageProductPickingTime?: Maybe<Scalars['Int']>;
  /**
   * average number of minutes taken to deliver each individual order for all
   * orders that have arrived (time from order en route to order arrived)
   * (formatted as shown in design min : sec)
   */
  averageRiderTime?: Maybe<Scalars['Int']>;
  /** Int value of count of all orders that are packed have not been accepted by a rider */
  pendingDeliveries?: Maybe<Scalars['Int']>;
  /** percentage of all “delivered“ hub orders that were on time */
  percentageOfDeliveriesOnTime?: Maybe<Scalars['Int']>;
  /** Int value of count of all orders that have not been accepted by picker */
  pickerAwaiting?: Maybe<Scalars['Int']>;
  /** Int value of count of all hub orders today */
  totalOrders?: Maybe<Scalars['Int']>;
};

export type Line = {
  __typename?: 'Line';
  availableQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shelfNo?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  eta?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  ts?: Maybe<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignRider?: Maybe<ActualTrip>;
  tripAction?: Maybe<ActualTrip>;
  unstack?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationAssignRiderArgs = {
  orderIds: Array<Scalars['String']>;
  riderId: Scalars['String'];
};

export type MutationTripActionArgs = {
  riderId: Scalars['String'];
  tripActionType: Scalars['String'];
  tripId: Scalars['String'];
};

export type MutationUnstackArgs = {
  hub: Scalars['String'];
  orders: Array<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  basketSize?: Maybe<Scalars['Int']>;
  containerIds?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Customer>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  deliveryCoordinates?: Maybe<Location>;
  deliveryDuration?: Maybe<Scalars['Int']>;
  deliveryWindowEnd?: Maybe<Scalars['String']>;
  deliveryWindowStart?: Maybe<Scalars['String']>;
  externalOrderNumber?: Maybe<Scalars['String']>;
  externalProvider?: Maybe<ExternalProvider>;
  id?: Maybe<Scalars['UUID']>;
  isOutsourced?: Maybe<Scalars['Boolean']>;
  isPlanned?: Maybe<Scalars['Boolean']>;
  lines?: Maybe<Array<Maybe<Line>>>;
  orderNumber?: Maybe<Scalars['String']>;
  outsourcingProviderName?: Maybe<OutsourcingProviderName>;
  packedAt?: Maybe<Scalars['DateTime']>;
  pickingDuration?: Maybe<Scalars['Int']>;
  plannedOrderInformation?: Maybe<PlannedOrderInformation>;
  promisedDeliveryDuration?: Maybe<Scalars['Int']>;
  rideDuration?: Maybe<Scalars['Int']>;
  rider?: Maybe<Rider>;
  riderId?: Maybe<Scalars['String']>;
  shelfNumbers?: Maybe<Array<Scalars['String']>>;
  shippingAddress?: Maybe<Address>;
  shippingMethod?: Maybe<ShippingMethod>;
  stackedOrderIds?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Money>;
  trackingId?: Maybe<Scalars['String']>;
  trackingProvider?: Maybe<TrackingProvider>;
};

export enum OutsourcingProviderName {
  UberDirect = 'UBER_DIRECT',
  Unspecified = 'UNSPECIFIED',
  WoltDrive = 'WOLT_DRIVE',
}

export type PlannedOrderInformation = {
  __typename?: 'PlannedOrderInformation';
  activeTimestamp?: Maybe<Scalars['String']>;
  deliveryWindowEnd?: Maybe<Scalars['String']>;
  deliveryWindowStart?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  bulkRiderLocations?: Maybe<Array<Maybe<RiderLocationResult>>>;
  hub: HubDetails;
  hubs: Array<Maybe<Hub>>;
  kpiStatistics?: Maybe<KpiStatistics>;
  orders?: Maybe<Array<Maybe<Order>>>;
  pickersNeeded: Scalars['Int'];
  riderLocations?: Maybe<Array<Maybe<Location>>>;
  searchOrders?: Maybe<Array<Maybe<Order>>>;
  searchRiders: Array<Rider>;
  tripsV2?: Maybe<HubTrips>;
  tripsWithOrders: Array<TripWithOrders>;
};

export type QueryBulkRiderLocationsArgs = {
  riderIds: Array<Scalars['String']>;
};

export type QueryHubArgs = {
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryHubsArgs = {
  country?: InputMaybe<Scalars['String']>;
  onlyAllowedHubs?: InputMaybe<Scalars['Boolean']>;
};

export type QueryKpiStatisticsArgs = {
  slug: Scalars['String'];
};

export type QueryOrdersArgs = {
  lastModifiedTimeSpan?: InputMaybe<Scalars['Int']>;
  modifiedSince?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type QueryPickersNeededArgs = {
  slug: Scalars['String'];
};

export type QueryRiderLocationsArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  riderId?: InputMaybe<Scalars['String']>;
};

export type QuerySearchOrdersArgs = {
  query?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type QuerySearchRidersArgs = {
  query?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};

export type QueryTripsV2Args = {
  slug: Scalars['String'];
};

export type QueryTripsWithOrdersArgs = {
  lastModifiedTimeSpan?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
};

export type Rider = {
  __typename?: 'Rider';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hubSlug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RiderIdWithCoords = {
  __typename?: 'RiderIdWithCoords';
  eta?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  rider_id?: Maybe<Scalars['String']>;
};

export type RiderIdWithError = {
  __typename?: 'RiderIdWithError';
  error?: Maybe<Scalars['String']>;
  rider_id?: Maybe<Scalars['String']>;
};

export type RiderLocationResult = RiderIdWithCoords | RiderIdWithError;

export enum ShippingMethod {
  ClickAndCollect = 'CLICK_AND_COLLECT',
  DynamicCountry = 'DYNAMIC_COUNTRY',
  ExternalPartner = 'EXTERNAL_PARTNER',
  I25Under_10FreeOver_45 = 'I25_UNDER_10_FREE_OVER_45',
  I29Under_15FreeOver_35 = 'I29_UNDER_15_FREE_OVER_35',
  I29Under_15FreeOver_39 = 'I29_UNDER_15_FREE_OVER_39',
  I29Under_20FreeOver_39 = 'I29_UNDER_20_FREE_OVER_39',
  I39Under_10FreeOver_35 = 'I39_UNDER_10_FREE_OVER_35',
  I39Under_10FreeOver_39 = 'I39_UNDER_10_FREE_OVER_39',
  I39Under_15FreeOver_39 = 'I39_UNDER_15_FREE_OVER_39',
  InStorePayment = 'IN_STORE_PAYMENT',
  LargeOrderFreeOver_35 = 'LARGE_ORDER_FREE_OVER_35',
  LargeOrderFreeOver_45 = 'LARGE_ORDER_FREE_OVER_45',
  SmallOrder_29Under_15 = 'SMALL_ORDER_29_UNDER_15',
  SmallOrder_39Under_10 = 'SMALL_ORDER_39_UNDER_10',
  SmallOrder_39Under_15 = 'SMALL_ORDER_39_UNDER_15',
  Standard = 'STANDARD',
  Unspecified = 'UNSPECIFIED',
}

export enum TrackingProvider {
  UberDirect = 'UBER_DIRECT',
  Unspecified = 'UNSPECIFIED',
}

export type Trip = {
  __typename?: 'Trip';
  heldBackFromPicking: Scalars['Boolean'];
  orderIds: Array<Scalars['UUID']>;
  orders?: Maybe<Array<Maybe<TripOrder>>>;
};

export type TripOrder = {
  __typename?: 'TripOrder';
  deliveryWindowEnd?: Maybe<Scalars['String']>;
  deliveryWindowStart?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  plannedOrderInformation?: Maybe<PlannedOrderInformation>;
};

export type TripWithOrders = {
  __typename?: 'TripWithOrders';
  heldBackFromPicking: Scalars['Boolean'];
  isStacked: Scalars['Boolean'];
  orders: Array<Order>;
};

export type Turf = {
  __typename?: 'Turf';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type SharedOrderFieldsFragment = {
  __typename?: 'Order';
  id?: string | null;
  basketSize?: number | null;
  createdAt?: string | null;
  shippingMethod?: ShippingMethod | null;
  externalProvider?: ExternalProvider | null;
  externalOrderNumber?: string | null;
  containerIds?: Array<string> | null;
  shelfNumbers?: Array<string> | null;
  stackedOrderIds?: Array<string> | null;
  deliveredAt?: string | null;
  deliveryDuration?: number | null;
  orderNumber?: string | null;
  packedAt?: string | null;
  pickingDuration?: number | null;
  promisedDeliveryDuration?: number | null;
  rideDuration?: number | null;
  state?: number | null;
  riderId?: string | null;
  trackingId?: string | null;
  trackingProvider?: TrackingProvider | null;
  isOutsourced?: boolean | null;
  outsourcingProviderName?: OutsourcingProviderName | null;
  isPlanned?: boolean | null;
  customer?: {
    __typename?: 'Customer';
    email?: string | null;
    isNewCustomer?: boolean | null;
  } | null;
  deliveryCoordinates?: {
    __typename?: 'Location';
    lat?: number | null;
    lng?: number | null;
  } | null;
  lines?: Array<{
    __typename?: 'Line';
    name?: string | null;
    quantity?: number | null;
    shelfNo?: string | null;
  } | null> | null;
  shippingAddress?: {
    __typename?: 'Address';
    buildingType?: string | null;
    nameOnDoor?: string | null;
    floorNumber?: string | null;
    entrance?: string | null;
    deliveryNote?: string | null;
    city?: string | null;
    email?: string | null;
    fax?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    mobile?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    title?: string | null;
    tag?: string | null;
  } | null;
  totalPrice?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
  plannedOrderInformation?: {
    __typename?: 'PlannedOrderInformation';
    deliveryWindowStart?: string | null;
    deliveryWindowEnd?: string | null;
  } | null;
};

export type SharedOrderFieldsWithoutPhoneFragment = {
  __typename?: 'Order';
  id?: string | null;
  basketSize?: number | null;
  createdAt?: string | null;
  shippingMethod?: ShippingMethod | null;
  externalProvider?: ExternalProvider | null;
  externalOrderNumber?: string | null;
  containerIds?: Array<string> | null;
  shelfNumbers?: Array<string> | null;
  stackedOrderIds?: Array<string> | null;
  deliveredAt?: string | null;
  deliveryDuration?: number | null;
  orderNumber?: string | null;
  packedAt?: string | null;
  pickingDuration?: number | null;
  promisedDeliveryDuration?: number | null;
  rideDuration?: number | null;
  state?: number | null;
  riderId?: string | null;
  trackingId?: string | null;
  trackingProvider?: TrackingProvider | null;
  isOutsourced?: boolean | null;
  outsourcingProviderName?: OutsourcingProviderName | null;
  isPlanned?: boolean | null;
  customer?: {
    __typename?: 'Customer';
    email?: string | null;
    isNewCustomer?: boolean | null;
  } | null;
  deliveryCoordinates?: {
    __typename?: 'Location';
    lat?: number | null;
    lng?: number | null;
  } | null;
  lines?: Array<{
    __typename?: 'Line';
    name?: string | null;
    quantity?: number | null;
    shelfNo?: string | null;
  } | null> | null;
  shippingAddress?: {
    __typename?: 'Address';
    buildingType?: string | null;
    nameOnDoor?: string | null;
    floorNumber?: string | null;
    entrance?: string | null;
    deliveryNote?: string | null;
    city?: string | null;
    email?: string | null;
    fax?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    postalCode?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    title?: string | null;
    tag?: string | null;
  } | null;
  totalPrice?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
  plannedOrderInformation?: {
    __typename?: 'PlannedOrderInformation';
    deliveryWindowStart?: string | null;
    deliveryWindowEnd?: string | null;
  } | null;
};

export type TripActionMutationVariables = Exact<{
  riderId: Scalars['String'];
  tripId: Scalars['String'];
  tripActionType: Scalars['String'];
}>;

export type TripActionMutation = {
  __typename?: 'Mutation';
  tripAction?: { __typename?: 'ActualTrip'; id?: string | null } | null;
};

export type UnstackTripMutationVariables = Exact<{
  hub: Scalars['String'];
  orders: Array<Scalars['String']> | Scalars['String'];
}>;

export type UnstackTripMutation = {
  __typename?: 'Mutation';
  unstack?: Array<string | null> | null;
};

export type AssignRiderMutationVariables = Exact<{
  riderId: Scalars['String'];
  orderIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type AssignRiderMutation = {
  __typename?: 'Mutation';
  assignRider?: { __typename?: 'ActualTrip'; id?: string | null } | null;
};

export type GetHubDetailsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type GetHubDetailsQuery = {
  __typename?: 'Query';
  hub: {
    __typename?: 'HubDetails';
    coordinates?: { __typename?: 'Location'; lat?: number | null; lng?: number | null } | null;
    turfs?: Array<Array<{
      __typename?: 'Turf';
      latitude?: number | null;
      longitude?: number | null;
    } | null> | null> | null;
  };
};

export type GetKpiStatisticsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetKpiStatisticsQuery = {
  __typename?: 'Query';
  kpiStatistics?: {
    __typename?: 'KPIStatistics';
    totalOrders?: number | null;
    averageRiderTime?: number | null;
    percentageOfDeliveriesOnTime?: number | null;
    averageProductPickingTime?: number | null;
    averageFulfillmentTime?: number | null;
  } | null;
};

export type GetOrdersWithTripsAndRidersQueryVariables = Exact<{
  slug: Scalars['String'];
  lastModifiedTimeSpan?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrdersWithTripsAndRidersQuery = {
  __typename?: 'Query';
  tripsV2?: {
    __typename?: 'HubTrips';
    bottleneck?: string | null;
    trips?: Array<{
      __typename?: 'Trip';
      orderIds: Array<string>;
      heldBackFromPicking: boolean;
      orders?: Array<{
        __typename?: 'TripOrder';
        orderId: string;
        deliveryWindowStart?: string | null;
        deliveryWindowEnd?: string | null;
        plannedOrderInformation?: {
          __typename?: 'PlannedOrderInformation';
          activeTimestamp?: string | null;
          deliveryWindowStart?: string | null;
          deliveryWindowEnd?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
  orders?: Array<{
    __typename?: 'Order';
    id?: string | null;
    basketSize?: number | null;
    createdAt?: string | null;
    shippingMethod?: ShippingMethod | null;
    externalProvider?: ExternalProvider | null;
    externalOrderNumber?: string | null;
    containerIds?: Array<string> | null;
    shelfNumbers?: Array<string> | null;
    stackedOrderIds?: Array<string> | null;
    deliveredAt?: string | null;
    deliveryDuration?: number | null;
    orderNumber?: string | null;
    packedAt?: string | null;
    pickingDuration?: number | null;
    promisedDeliveryDuration?: number | null;
    rideDuration?: number | null;
    state?: number | null;
    riderId?: string | null;
    trackingId?: string | null;
    trackingProvider?: TrackingProvider | null;
    isOutsourced?: boolean | null;
    outsourcingProviderName?: OutsourcingProviderName | null;
    isPlanned?: boolean | null;
    rider?: {
      __typename?: 'Rider';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      status?: string | null;
    } | null;
    customer?: {
      __typename?: 'Customer';
      email?: string | null;
      isNewCustomer?: boolean | null;
    } | null;
    deliveryCoordinates?: {
      __typename?: 'Location';
      lat?: number | null;
      lng?: number | null;
    } | null;
    lines?: Array<{
      __typename?: 'Line';
      name?: string | null;
      quantity?: number | null;
      shelfNo?: string | null;
    } | null> | null;
    shippingAddress?: {
      __typename?: 'Address';
      buildingType?: string | null;
      nameOnDoor?: string | null;
      floorNumber?: string | null;
      entrance?: string | null;
      deliveryNote?: string | null;
      city?: string | null;
      email?: string | null;
      fax?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      mobile?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      title?: string | null;
      tag?: string | null;
    } | null;
    totalPrice?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
    plannedOrderInformation?: {
      __typename?: 'PlannedOrderInformation';
      deliveryWindowStart?: string | null;
      deliveryWindowEnd?: string | null;
    } | null;
  } | null> | null;
};

export type GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables = Exact<{
  slug: Scalars['String'];
  lastModifiedTimeSpan?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrdersWithTripsAndRidersWithoutPhoneQuery = {
  __typename?: 'Query';
  tripsV2?: {
    __typename?: 'HubTrips';
    bottleneck?: string | null;
    trips?: Array<{
      __typename?: 'Trip';
      orderIds: Array<string>;
      heldBackFromPicking: boolean;
      orders?: Array<{
        __typename?: 'TripOrder';
        orderId: string;
        deliveryWindowStart?: string | null;
        deliveryWindowEnd?: string | null;
        plannedOrderInformation?: {
          __typename?: 'PlannedOrderInformation';
          activeTimestamp?: string | null;
          deliveryWindowStart?: string | null;
          deliveryWindowEnd?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
  orders?: Array<{
    __typename?: 'Order';
    id?: string | null;
    basketSize?: number | null;
    createdAt?: string | null;
    shippingMethod?: ShippingMethod | null;
    externalProvider?: ExternalProvider | null;
    externalOrderNumber?: string | null;
    containerIds?: Array<string> | null;
    shelfNumbers?: Array<string> | null;
    stackedOrderIds?: Array<string> | null;
    deliveredAt?: string | null;
    deliveryDuration?: number | null;
    orderNumber?: string | null;
    packedAt?: string | null;
    pickingDuration?: number | null;
    promisedDeliveryDuration?: number | null;
    rideDuration?: number | null;
    state?: number | null;
    riderId?: string | null;
    trackingId?: string | null;
    trackingProvider?: TrackingProvider | null;
    isOutsourced?: boolean | null;
    outsourcingProviderName?: OutsourcingProviderName | null;
    isPlanned?: boolean | null;
    rider?: {
      __typename?: 'Rider';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      status?: string | null;
    } | null;
    customer?: {
      __typename?: 'Customer';
      email?: string | null;
      isNewCustomer?: boolean | null;
    } | null;
    deliveryCoordinates?: {
      __typename?: 'Location';
      lat?: number | null;
      lng?: number | null;
    } | null;
    lines?: Array<{
      __typename?: 'Line';
      name?: string | null;
      quantity?: number | null;
      shelfNo?: string | null;
    } | null> | null;
    shippingAddress?: {
      __typename?: 'Address';
      buildingType?: string | null;
      nameOnDoor?: string | null;
      floorNumber?: string | null;
      entrance?: string | null;
      deliveryNote?: string | null;
      city?: string | null;
      email?: string | null;
      fax?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      postalCode?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      title?: string | null;
      tag?: string | null;
    } | null;
    totalPrice?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
    plannedOrderInformation?: {
      __typename?: 'PlannedOrderInformation';
      deliveryWindowStart?: string | null;
      deliveryWindowEnd?: string | null;
    } | null;
  } | null> | null;
};

export type GetPickersNeededQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetPickersNeededQuery = { __typename?: 'Query'; pickersNeeded: number };

export type GetRiderLocationsQueryVariables = Exact<{
  riderId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
}>;

export type GetRiderLocationsQuery = {
  __typename?: 'Query';
  riderLocations?: Array<{
    __typename?: 'Location';
    lat?: number | null;
    lng?: number | null;
    eta?: number | null;
    ts?: string | null;
  } | null> | null;
};

export type GetAllRiderLocationsQueryVariables = Exact<{
  riderIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetAllRiderLocationsQuery = {
  __typename?: 'Query';
  bulkRiderLocations?: Array<
    | {
        __typename?: 'RiderIdWithCoords';
        rider_id?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        eta?: number | null;
      }
    | { __typename?: 'RiderIdWithError'; rider_id?: string | null; error?: string | null }
    | null
  > | null;
};

export type SearchRidersQueryVariables = Exact<{
  slug: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}>;

export type SearchRidersQuery = {
  __typename?: 'Query';
  searchRiders: Array<{
    __typename?: 'Rider';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  }>;
};

export type SearchOrdersQueryVariables = Exact<{
  slug: Scalars['String'];
  query: Scalars['String'];
  includeRider: Scalars['Boolean'];
}>;

export type SearchOrdersQuery = {
  __typename?: 'Query';
  searchOrders?: Array<{
    __typename?: 'Order';
    riderId?: string | null;
    id?: string | null;
    basketSize?: number | null;
    createdAt?: string | null;
    shippingMethod?: ShippingMethod | null;
    externalProvider?: ExternalProvider | null;
    externalOrderNumber?: string | null;
    containerIds?: Array<string> | null;
    shelfNumbers?: Array<string> | null;
    stackedOrderIds?: Array<string> | null;
    deliveredAt?: string | null;
    deliveryDuration?: number | null;
    orderNumber?: string | null;
    packedAt?: string | null;
    pickingDuration?: number | null;
    promisedDeliveryDuration?: number | null;
    rideDuration?: number | null;
    state?: number | null;
    trackingId?: string | null;
    trackingProvider?: TrackingProvider | null;
    isOutsourced?: boolean | null;
    outsourcingProviderName?: OutsourcingProviderName | null;
    isPlanned?: boolean | null;
    rider?: {
      __typename?: 'Rider';
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
    } | null;
    customer?: {
      __typename?: 'Customer';
      email?: string | null;
      isNewCustomer?: boolean | null;
    } | null;
    deliveryCoordinates?: {
      __typename?: 'Location';
      lat?: number | null;
      lng?: number | null;
    } | null;
    lines?: Array<{
      __typename?: 'Line';
      name?: string | null;
      quantity?: number | null;
      shelfNo?: string | null;
    } | null> | null;
    shippingAddress?: {
      __typename?: 'Address';
      buildingType?: string | null;
      nameOnDoor?: string | null;
      floorNumber?: string | null;
      entrance?: string | null;
      deliveryNote?: string | null;
      city?: string | null;
      email?: string | null;
      fax?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      mobile?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      title?: string | null;
      tag?: string | null;
    } | null;
    totalPrice?: { __typename?: 'Money'; amount?: number | null; currency?: string | null } | null;
    plannedOrderInformation?: {
      __typename?: 'PlannedOrderInformation';
      deliveryWindowStart?: string | null;
      deliveryWindowEnd?: string | null;
    } | null;
  } | null> | null;
};

export const SharedOrderFieldsFragmentDoc = gql`
  fragment SharedOrderFields on Order {
    id
    basketSize
    createdAt
    shippingMethod
    externalProvider
    externalOrderNumber
    containerIds
    shelfNumbers
    stackedOrderIds
    customer {
      email
      isNewCustomer
    }
    deliveredAt
    deliveryDuration
    deliveryCoordinates {
      lat
      lng
    }
    lines {
      name
      quantity
      shelfNo
    }
    orderNumber
    packedAt
    pickingDuration
    promisedDeliveryDuration
    rideDuration
    shippingAddress {
      buildingType
      nameOnDoor
      floorNumber
      entrance
      deliveryNote
      city
      email
      fax
      firstName
      lastName
      mobile
      phone
      postalCode
      streetName
      streetNumber
      title
      tag
    }
    state
    totalPrice {
      amount
      currency
    }
    riderId
    trackingId
    trackingProvider
    isOutsourced
    outsourcingProviderName
    isPlanned
    plannedOrderInformation {
      deliveryWindowStart
      deliveryWindowEnd
    }
  }
`;
export const SharedOrderFieldsWithoutPhoneFragmentDoc = gql`
  fragment SharedOrderFieldsWithoutPhone on Order {
    id
    basketSize
    createdAt
    shippingMethod
    externalProvider
    externalOrderNumber
    containerIds
    shelfNumbers
    stackedOrderIds
    customer {
      email
      isNewCustomer
    }
    deliveredAt
    deliveryDuration
    deliveryCoordinates {
      lat
      lng
    }
    lines {
      name
      quantity
      shelfNo
    }
    orderNumber
    packedAt
    pickingDuration
    promisedDeliveryDuration
    rideDuration
    shippingAddress {
      buildingType
      nameOnDoor
      floorNumber
      entrance
      deliveryNote
      city
      email
      fax
      firstName
      lastName
      postalCode
      streetName
      streetNumber
      title
      tag
    }
    state
    totalPrice {
      amount
      currency
    }
    riderId
    trackingId
    trackingProvider
    isOutsourced
    outsourcingProviderName
    isPlanned
    plannedOrderInformation {
      deliveryWindowStart
      deliveryWindowEnd
    }
  }
`;
export const TripActionDocument = gql`
  mutation TripAction($riderId: String!, $tripId: String!, $tripActionType: String!) {
    tripAction(riderId: $riderId, tripId: $tripId, tripActionType: $tripActionType) {
      id
    }
  }
`;
export type TripActionMutationFn = Apollo.MutationFunction<
  TripActionMutation,
  TripActionMutationVariables
>;

/**
 * __useTripActionMutation__
 *
 * To run a mutation, you first call `useTripActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTripActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tripActionMutation, { data, loading, error }] = useTripActionMutation({
 *   variables: {
 *      riderId: // value for 'riderId'
 *      tripId: // value for 'tripId'
 *      tripActionType: // value for 'tripActionType'
 *   },
 * });
 */
export function useTripActionMutation(
  baseOptions?: Apollo.MutationHookOptions<TripActionMutation, TripActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TripActionMutation, TripActionMutationVariables>(
    TripActionDocument,
    options
  );
}
export type TripActionMutationHookResult = ReturnType<typeof useTripActionMutation>;
export type TripActionMutationResult = Apollo.MutationResult<TripActionMutation>;
export type TripActionMutationOptions = Apollo.BaseMutationOptions<
  TripActionMutation,
  TripActionMutationVariables
>;
export const UnstackTripDocument = gql`
  mutation UnstackTrip($hub: String!, $orders: [String!]!) {
    unstack(hub: $hub, orders: $orders)
  }
`;
export type UnstackTripMutationFn = Apollo.MutationFunction<
  UnstackTripMutation,
  UnstackTripMutationVariables
>;

/**
 * __useUnstackTripMutation__
 *
 * To run a mutation, you first call `useUnstackTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstackTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstackTripMutation, { data, loading, error }] = useUnstackTripMutation({
 *   variables: {
 *      hub: // value for 'hub'
 *      orders: // value for 'orders'
 *   },
 * });
 */
export function useUnstackTripMutation(
  baseOptions?: Apollo.MutationHookOptions<UnstackTripMutation, UnstackTripMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnstackTripMutation, UnstackTripMutationVariables>(
    UnstackTripDocument,
    options
  );
}
export type UnstackTripMutationHookResult = ReturnType<typeof useUnstackTripMutation>;
export type UnstackTripMutationResult = Apollo.MutationResult<UnstackTripMutation>;
export type UnstackTripMutationOptions = Apollo.BaseMutationOptions<
  UnstackTripMutation,
  UnstackTripMutationVariables
>;
export const AssignRiderDocument = gql`
  mutation AssignRider($riderId: String!, $orderIds: [String!]!) {
    assignRider(riderId: $riderId, orderIds: $orderIds) {
      id
    }
  }
`;
export type AssignRiderMutationFn = Apollo.MutationFunction<
  AssignRiderMutation,
  AssignRiderMutationVariables
>;

/**
 * __useAssignRiderMutation__
 *
 * To run a mutation, you first call `useAssignRiderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRiderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRiderMutation, { data, loading, error }] = useAssignRiderMutation({
 *   variables: {
 *      riderId: // value for 'riderId'
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useAssignRiderMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignRiderMutation, AssignRiderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignRiderMutation, AssignRiderMutationVariables>(
    AssignRiderDocument,
    options
  );
}
export type AssignRiderMutationHookResult = ReturnType<typeof useAssignRiderMutation>;
export type AssignRiderMutationResult = Apollo.MutationResult<AssignRiderMutation>;
export type AssignRiderMutationOptions = Apollo.BaseMutationOptions<
  AssignRiderMutation,
  AssignRiderMutationVariables
>;
export const GetHubDetailsDocument = gql`
  query GetHubDetails($slug: String) {
    hub(slug: $slug) {
      coordinates {
        lat
        lng
      }
      turfs {
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useGetHubDetailsQuery__
 *
 * To run a query within a React component, call `useGetHubDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetHubDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHubDetailsQuery, GetHubDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHubDetailsQuery, GetHubDetailsQueryVariables>(
    GetHubDetailsDocument,
    options
  );
}
export function useGetHubDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHubDetailsQuery, GetHubDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHubDetailsQuery, GetHubDetailsQueryVariables>(
    GetHubDetailsDocument,
    options
  );
}
export type GetHubDetailsQueryHookResult = ReturnType<typeof useGetHubDetailsQuery>;
export type GetHubDetailsLazyQueryHookResult = ReturnType<typeof useGetHubDetailsLazyQuery>;
export type GetHubDetailsQueryResult = Apollo.QueryResult<
  GetHubDetailsQuery,
  GetHubDetailsQueryVariables
>;
export const GetKpiStatisticsDocument = gql`
  query GetKpiStatistics($slug: String!) {
    kpiStatistics(slug: $slug) {
      totalOrders
      averageRiderTime
      percentageOfDeliveriesOnTime
      averageProductPickingTime
      averageFulfillmentTime
    }
  }
`;

/**
 * __useGetKpiStatisticsQuery__
 *
 * To run a query within a React component, call `useGetKpiStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiStatisticsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetKpiStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<GetKpiStatisticsQuery, GetKpiStatisticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKpiStatisticsQuery, GetKpiStatisticsQueryVariables>(
    GetKpiStatisticsDocument,
    options
  );
}
export function useGetKpiStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKpiStatisticsQuery, GetKpiStatisticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKpiStatisticsQuery, GetKpiStatisticsQueryVariables>(
    GetKpiStatisticsDocument,
    options
  );
}
export type GetKpiStatisticsQueryHookResult = ReturnType<typeof useGetKpiStatisticsQuery>;
export type GetKpiStatisticsLazyQueryHookResult = ReturnType<typeof useGetKpiStatisticsLazyQuery>;
export type GetKpiStatisticsQueryResult = Apollo.QueryResult<
  GetKpiStatisticsQuery,
  GetKpiStatisticsQueryVariables
>;
export const GetOrdersWithTripsAndRidersDocument = gql`
  query GetOrdersWithTripsAndRiders($slug: String!, $lastModifiedTimeSpan: Int) {
    tripsV2(slug: $slug) {
      bottleneck
      trips {
        orderIds
        orders {
          orderId
          deliveryWindowStart
          deliveryWindowEnd
          plannedOrderInformation {
            activeTimestamp
            deliveryWindowStart
            deliveryWindowEnd
          }
        }
        heldBackFromPicking
      }
    }
    orders(slug: $slug, lastModifiedTimeSpan: $lastModifiedTimeSpan) {
      ...SharedOrderFields
      rider {
        id
        firstName
        lastName
        phoneNumber
        status
      }
    }
  }
  ${SharedOrderFieldsFragmentDoc}
`;

/**
 * __useGetOrdersWithTripsAndRidersQuery__
 *
 * To run a query within a React component, call `useGetOrdersWithTripsAndRidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersWithTripsAndRidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersWithTripsAndRidersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      lastModifiedTimeSpan: // value for 'lastModifiedTimeSpan'
 *   },
 * });
 */
export function useGetOrdersWithTripsAndRidersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrdersWithTripsAndRidersQuery,
    GetOrdersWithTripsAndRidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrdersWithTripsAndRidersQuery,
    GetOrdersWithTripsAndRidersQueryVariables
  >(GetOrdersWithTripsAndRidersDocument, options);
}
export function useGetOrdersWithTripsAndRidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersWithTripsAndRidersQuery,
    GetOrdersWithTripsAndRidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrdersWithTripsAndRidersQuery,
    GetOrdersWithTripsAndRidersQueryVariables
  >(GetOrdersWithTripsAndRidersDocument, options);
}
export type GetOrdersWithTripsAndRidersQueryHookResult = ReturnType<
  typeof useGetOrdersWithTripsAndRidersQuery
>;
export type GetOrdersWithTripsAndRidersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersWithTripsAndRidersLazyQuery
>;
export type GetOrdersWithTripsAndRidersQueryResult = Apollo.QueryResult<
  GetOrdersWithTripsAndRidersQuery,
  GetOrdersWithTripsAndRidersQueryVariables
>;
export const GetOrdersWithTripsAndRidersWithoutPhoneDocument = gql`
  query GetOrdersWithTripsAndRidersWithoutPhone($slug: String!, $lastModifiedTimeSpan: Int) {
    tripsV2(slug: $slug) {
      bottleneck
      trips {
        orderIds
        orders {
          orderId
          deliveryWindowStart
          deliveryWindowEnd
          plannedOrderInformation {
            activeTimestamp
            deliveryWindowStart
            deliveryWindowEnd
          }
        }
        heldBackFromPicking
      }
    }
    orders(slug: $slug, lastModifiedTimeSpan: $lastModifiedTimeSpan) {
      ...SharedOrderFieldsWithoutPhone
      rider {
        id
        firstName
        lastName
        phoneNumber
        status
      }
    }
  }
  ${SharedOrderFieldsWithoutPhoneFragmentDoc}
`;

/**
 * __useGetOrdersWithTripsAndRidersWithoutPhoneQuery__
 *
 * To run a query within a React component, call `useGetOrdersWithTripsAndRidersWithoutPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersWithTripsAndRidersWithoutPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersWithTripsAndRidersWithoutPhoneQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      lastModifiedTimeSpan: // value for 'lastModifiedTimeSpan'
 *   },
 * });
 */
export function useGetOrdersWithTripsAndRidersWithoutPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrdersWithTripsAndRidersWithoutPhoneQuery,
    GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrdersWithTripsAndRidersWithoutPhoneQuery,
    GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables
  >(GetOrdersWithTripsAndRidersWithoutPhoneDocument, options);
}
export function useGetOrdersWithTripsAndRidersWithoutPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersWithTripsAndRidersWithoutPhoneQuery,
    GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrdersWithTripsAndRidersWithoutPhoneQuery,
    GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables
  >(GetOrdersWithTripsAndRidersWithoutPhoneDocument, options);
}
export type GetOrdersWithTripsAndRidersWithoutPhoneQueryHookResult = ReturnType<
  typeof useGetOrdersWithTripsAndRidersWithoutPhoneQuery
>;
export type GetOrdersWithTripsAndRidersWithoutPhoneLazyQueryHookResult = ReturnType<
  typeof useGetOrdersWithTripsAndRidersWithoutPhoneLazyQuery
>;
export type GetOrdersWithTripsAndRidersWithoutPhoneQueryResult = Apollo.QueryResult<
  GetOrdersWithTripsAndRidersWithoutPhoneQuery,
  GetOrdersWithTripsAndRidersWithoutPhoneQueryVariables
>;
export const GetPickersNeededDocument = gql`
  query GetPickersNeeded($slug: String!) {
    pickersNeeded(slug: $slug)
  }
`;

/**
 * __useGetPickersNeededQuery__
 *
 * To run a query within a React component, call `useGetPickersNeededQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickersNeededQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickersNeededQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPickersNeededQuery(
  baseOptions: Apollo.QueryHookOptions<GetPickersNeededQuery, GetPickersNeededQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPickersNeededQuery, GetPickersNeededQueryVariables>(
    GetPickersNeededDocument,
    options
  );
}
export function useGetPickersNeededLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPickersNeededQuery, GetPickersNeededQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPickersNeededQuery, GetPickersNeededQueryVariables>(
    GetPickersNeededDocument,
    options
  );
}
export type GetPickersNeededQueryHookResult = ReturnType<typeof useGetPickersNeededQuery>;
export type GetPickersNeededLazyQueryHookResult = ReturnType<typeof useGetPickersNeededLazyQuery>;
export type GetPickersNeededQueryResult = Apollo.QueryResult<
  GetPickersNeededQuery,
  GetPickersNeededQueryVariables
>;
export const GetRiderLocationsDocument = gql`
  query GetRiderLocations($riderId: String, $orderId: String) {
    riderLocations(riderId: $riderId, orderId: $orderId) {
      lat
      lng
      eta
      ts
    }
  }
`;

/**
 * __useGetRiderLocationsQuery__
 *
 * To run a query within a React component, call `useGetRiderLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiderLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiderLocationsQuery({
 *   variables: {
 *      riderId: // value for 'riderId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetRiderLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRiderLocationsQuery, GetRiderLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRiderLocationsQuery, GetRiderLocationsQueryVariables>(
    GetRiderLocationsDocument,
    options
  );
}
export function useGetRiderLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRiderLocationsQuery, GetRiderLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRiderLocationsQuery, GetRiderLocationsQueryVariables>(
    GetRiderLocationsDocument,
    options
  );
}
export type GetRiderLocationsQueryHookResult = ReturnType<typeof useGetRiderLocationsQuery>;
export type GetRiderLocationsLazyQueryHookResult = ReturnType<typeof useGetRiderLocationsLazyQuery>;
export type GetRiderLocationsQueryResult = Apollo.QueryResult<
  GetRiderLocationsQuery,
  GetRiderLocationsQueryVariables
>;
export const GetAllRiderLocationsDocument = gql`
  query GetAllRiderLocations($riderIds: [String!]!) {
    bulkRiderLocations(riderIds: $riderIds) {
      ... on RiderIdWithCoords {
        rider_id
        latitude
        longitude
        eta
      }
      ... on RiderIdWithError {
        rider_id
        error
      }
    }
  }
`;

/**
 * __useGetAllRiderLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllRiderLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRiderLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRiderLocationsQuery({
 *   variables: {
 *      riderIds: // value for 'riderIds'
 *   },
 * });
 */
export function useGetAllRiderLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllRiderLocationsQuery,
    GetAllRiderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRiderLocationsQuery, GetAllRiderLocationsQueryVariables>(
    GetAllRiderLocationsDocument,
    options
  );
}
export function useGetAllRiderLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRiderLocationsQuery,
    GetAllRiderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRiderLocationsQuery, GetAllRiderLocationsQueryVariables>(
    GetAllRiderLocationsDocument,
    options
  );
}
export type GetAllRiderLocationsQueryHookResult = ReturnType<typeof useGetAllRiderLocationsQuery>;
export type GetAllRiderLocationsLazyQueryHookResult = ReturnType<
  typeof useGetAllRiderLocationsLazyQuery
>;
export type GetAllRiderLocationsQueryResult = Apollo.QueryResult<
  GetAllRiderLocationsQuery,
  GetAllRiderLocationsQueryVariables
>;
export const SearchRidersDocument = gql`
  query SearchRiders($slug: String!, $query: String, $status: String) {
    searchRiders(slug: $slug, query: $query, status: $status) {
      id
      firstName
      lastName
      email
    }
  }
`;

/**
 * __useSearchRidersQuery__
 *
 * To run a query within a React component, call `useSearchRidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRidersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      query: // value for 'query'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSearchRidersQuery(
  baseOptions: Apollo.QueryHookOptions<SearchRidersQuery, SearchRidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchRidersQuery, SearchRidersQueryVariables>(
    SearchRidersDocument,
    options
  );
}
export function useSearchRidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchRidersQuery, SearchRidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchRidersQuery, SearchRidersQueryVariables>(
    SearchRidersDocument,
    options
  );
}
export type SearchRidersQueryHookResult = ReturnType<typeof useSearchRidersQuery>;
export type SearchRidersLazyQueryHookResult = ReturnType<typeof useSearchRidersLazyQuery>;
export type SearchRidersQueryResult = Apollo.QueryResult<
  SearchRidersQuery,
  SearchRidersQueryVariables
>;
export const SearchOrdersDocument = gql`
  query SearchOrders($slug: String!, $query: String!, $includeRider: Boolean!) {
    searchOrders(slug: $slug, query: $query) {
      ...SharedOrderFields
      riderId @include(if: $includeRider)
      rider @include(if: $includeRider) {
        firstName
        lastName
        phoneNumber
      }
    }
  }
  ${SharedOrderFieldsFragmentDoc}
`;

/**
 * __useSearchOrdersQuery__
 *
 * To run a query within a React component, call `useSearchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrdersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      query: // value for 'query'
 *      includeRider: // value for 'includeRider'
 *   },
 * });
 */
export function useSearchOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<SearchOrdersQuery, SearchOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(
    SearchOrdersDocument,
    options
  );
}
export function useSearchOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchOrdersQuery, SearchOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(
    SearchOrdersDocument,
    options
  );
}
export type SearchOrdersQueryHookResult = ReturnType<typeof useSearchOrdersQuery>;
export type SearchOrdersLazyQueryHookResult = ReturnType<typeof useSearchOrdersLazyQuery>;
export type SearchOrdersQueryResult = Apollo.QueryResult<
  SearchOrdersQuery,
  SearchOrdersQueryVariables
>;
