import moment from 'moment-timezone';
import { OpsAssociate } from 'src/models/ops-associate';
import {
  ChecksTypes,
  JobTitles,
  OAStatesColors,
  RiderStateColors,
} from 'utils/constants';
import { Rider } from 'src/models/rider';

export type SortDirection = 'ASC' | 'DESC' | null;

export const calculatePercentage = (
  completedValue: number,
  totalValue: number
): number => {
  const percentage = (completedValue / totalValue) * 100;
  if (isNaN(percentage) || !isFinite(percentage)) {
    return 0;
  } else {
    return parseFloat(percentage.toFixed());
  }
};

export const calculateTimeInState = (time: string): string => {
  const date = new Date(time);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const secondsRounded = seconds % 60;
  const minutes = Math.floor(diff / 1000 / 60) % 60;

  return minutes < 10
    ? `0${minutes}:${secondsRounded}`
    : `${minutes}:${secondsRounded}`;
};

export const splitChecksData = (
  checks: Check[]
): {
  stockCheckP0: (Check | undefined)[];
  freshChecksRoutine: (Check | undefined)[];
  bbdChecksRoutine: (Check | undefined)[];
  stockCheckP1: (Check | undefined)[];
  manualCheck: (Check | undefined)[];
} => {
  const stockCheckP0: Check[] = [];
  const freshChecksRoutine: Check[] = [];
  const bbdChecksRoutine: Check[] = [];
  const stockCheckP1: Check[] = [];
  const manualCheck: Check[] = [];

  checks.forEach((check: any) => {
    if (check.type === ChecksTypes.STOCK_CHECK_P0 && !check.is_completed) {
      stockCheckP0.push(check);
    } else if (check.type === ChecksTypes.FRESH_CHECK) {
      freshChecksRoutine.push(check);
    } else if (check.type === ChecksTypes.BBD_CHECK) {
      bbdChecksRoutine.push(check);
    } else if (check.type === ChecksTypes.STOCK_CHECK_P1) {
      stockCheckP1.push(check);
    } else if (check.type === ChecksTypes.MANUAL_CHECK) {
      manualCheck.push(check);
    }
  });

  return {
    stockCheckP0,
    freshChecksRoutine,
    bbdChecksRoutine,
    stockCheckP1,
    manualCheck,
  };
};

export const findOldestTask = (tasks: any): any => {
  let oldestTask: any = null;
  tasks.forEach((task: any) => {
    if (
      !oldestTask ||
      moment(task.created_at).isBefore(moment(oldestTask?.created_at))
    ) {
      oldestTask = task;
    }
  });

  return oldestTask;
};

export const findUncompletedTasks = (tasks: any): any => {
  return tasks.filter((task: any) => !task.is_completed);
};

export const isDeadlinePassed = (deadlineAt: string): boolean => {
  const currentTime = moment.utc();
  const givenTime = moment(deadlineAt);

  return moment(currentTime).isAfter(givenTime);
};

export const setChecksPayload = (type, sectionData): any => {
  if (!sectionData) {
    return {
      type,
      is_completed: false,
      remaining_checks_count: 0,
      completed_percentage: 0,
    };
  } else {
    const remainingChecks = findUncompletedTasks(sectionData);
    const oldestTask = findOldestTask(remainingChecks);

    const completedChecks = sectionData.length - remainingChecks.length;

    return {
      remaining_checks_count: remainingChecks.length,
      all_checks_count: sectionData.length,
      type,
      is_deadline_passed:
        remainingChecks.length > 0 && isDeadlinePassed(oldestTask!.deadline_at),
      completed_percentage: calculatePercentage(
        completedChecks,
        sectionData.length
      ),
      completed_checks_count: completedChecks,
    };
  }
};

export const capitalizeOrUppercaseJobTitle = (jobTitle: string): string => {
  if (jobTitle === JobTitles.EXT) {
    return jobTitle.charAt(0).toUpperCase() + jobTitle.slice(1);
  } else {
    return jobTitle.toUpperCase();
  }
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert hours and minutes to strings and pad single digit minutes with a leading zero
  const hoursStr = hours < 10 ? `0${hours.toString()}` : hours.toString();
  const minutesStr =
    minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

  return `${hoursStr}:${minutesStr}`;
};

// Function to convert start and end times into a time range
export const convertToTimeRange = (
  shiftStart: string,
  shiftEnd: string
): string => {
  const startTime = formatTime(shiftStart);
  const endTime = formatTime(shiftEnd);
  return `${startTime} – ${endTime}`;
};

// Function to format punch-in time
export const formatPunchInTime = (punchIn: string): string => {
  return formatTime(punchIn);
};

export const sortByNameOrPunchIn = (
  dataSet: OpsAssociate[] | Rider[],
  key: string,
  direction: SortDirection
): OpsAssociate[] | Rider[] => {
  return [...dataSet].sort((a, b) => {
    if (a[key] === null) {
      return direction === 'ASC' ? -1 : 1;
    }
    if (b[key] === null) {
      return direction === 'ASC' ? 1 : -1;
    }
    return direction === 'ASC'
      ? a[key].localeCompare(b[key])
      : b[key].localeCompare(a[key]);
  });
};

export const sortByNumericField = (
  dataSet: OpsAssociate[] | Rider[],
  key: string,
  direction: SortDirection
): OpsAssociate[] | Rider[] => {
  return [...dataSet].sort((a, b) => {
    const aValue = a[key] ?? 0;
    const bValue = b[key] ?? 0;

    return direction === 'ASC' ? aValue - bValue : bValue - aValue;
  });
};

export const parseTimeToMinutes = (
  time: string | null,
  unit: 'h' | 'm'
): number => {
  if (time === null) {
    return 0;
  }

  const parts = time
    .split(unit === 'h' ? 'h' : /[ms]/)
    .map((part) => parseInt(part, 10) || 0);
  return (parts[0] || 0) * 60 + (parts[1] || 0);
};

export const sortDataSetByTime = (
  dataSet: OpsAssociate[] | Rider[],
  key: string,
  direction: SortDirection,
  unit: 'h' | 'm'
): OpsAssociate[] | Rider[] => {
  return [...dataSet].sort((a, b) => {
    const aValue = parseTimeToMinutes(a[key], unit);
    const bValue = parseTimeToMinutes(b[key], unit);
    return direction === 'ASC' ? aValue - bValue : bValue - aValue;
  });
};

export const getOAActivityColor = (activity: string): string => {
  return OAStatesColors[activity] || 'bg-gray-300';
};

export const getRiderActivityColor = (activity: string): string => {
  return RiderStateColors[activity] || 'bg-gray-300';
};

export const sortActivities = (
  dataSet: OpsAssociate[] | Rider[],
  sortOrderSet: string[],
  direction: SortDirection
): OpsAssociate[] | Rider[] => {
  return [...dataSet].sort((a, b) => {
    const indexA = sortOrderSet.indexOf(a.activity.toLocaleLowerCase());
    const indexB = sortOrderSet.indexOf(b.activity.toLocaleLowerCase());

    if (indexA === -1 || indexB === -1) {
      return indexA === -1 ? 1 : -1;
    }

    return direction === 'ASC' ? indexA - indexB : indexB - indexA;
  });
};

export const isShiftStartLaterThanNow = (shiftStart: string): boolean => {
  const now = moment();
  const shiftStartTime = moment(shiftStart);

  return shiftStartTime.isAfter(now);
};

export const timeToMinutesFormatter = (
  seconds: number,
  decimalCount?: number
): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = (seconds % 60).toFixed(decimalCount || 0);

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours}h`;
  }

  if (minutes > 0 || hours > 0) {
    formattedDuration += `${minutes}m`;
  }

  formattedDuration += `${remainingSeconds}s`;

  return formattedDuration;
};

export const formatNumberToPercentage = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value / 100);
};
