import { Tab, Tabs, TabsVariant } from '@hubportal/components';
import useStore, { ActivitySections } from 'utils/store';
import { useEffect, useMemo } from 'react';
import { EmployeeLevel } from '../employee-level';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { Countries } from 'utils/constants';
import { Link } from 'react-router-dom';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import OrdersStatsWidget from 'components/OrdersStats';
import FullScreen from '../hub-level/full-screen';

const EMPLOYEE_LEVEL_TAB = '/activity/employee-level';

const LiveSteering = (): JSX.Element => {
  const { country, selectedHub, activitySection, setActivitySection } =
    useStore();
  const { isAllowed } = usePermissions();
  const { t } = useTranslation();
  const { path: currentPath } = useCurrentPath();

  const isEmployeeActivityAllowed =
    country === Countries.NL || isAllowed(Permission.EMPLOYEE_ACTIVITY);

  const currentTab = useMemo(() => {
    return currentPath === EMPLOYEE_LEVEL_TAB
      ? ActivitySections.EMPLOYEE
      : ActivitySections.HUB;
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === EMPLOYEE_LEVEL_TAB) {
      setActivitySection(ActivitySections.EMPLOYEE);
    } else {
      setActivitySection(ActivitySections.HUB);
    }
  }, [currentPath, setActivitySection]);

  return (
    <>
      <div className="w-screen">
        <div
          style={{ width: 'calc(100% - 150px)' }}
          className="min-h-[16vh] flex justify-between mx-10"
        >
          <div className="flex flex-col justify-end">
            <div className="flex flex-col justify-center header-m text-white whitespace-nowrap">
              <div className="flex flex-col text-center justify-center items-center">
                <h1 className="mr-[0.35vw] headline-value">
                  {selectedHub?.name}
                </h1>
                <div className="h-[1vw]"></div>
                {activitySection === ActivitySections.HUB && <FullScreen />}
              </div>
            </div>
            <div className="pt-[1vw]">
              <Tabs
                value={currentTab}
                onChange={() => {}}
                variant={TabsVariant.secondary}
              >
                <Tab id={ActivitySections.HUB}>
                  <Link reloadDocument to="/activity">
                    <span className="subheadline-title">
                      {t('workforce_kpi.hub_level.tab.title')}
                    </span>
                  </Link>
                </Tab>

                {isEmployeeActivityAllowed && (
                  <Tab id={ActivitySections.EMPLOYEE}>
                    <Link reloadDocument to="/activity/employee-level">
                      <span className="subheadline-title">
                        {t('workforce_kpi.employee.tab.title')}
                      </span>
                    </Link>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
          <div className={`flex justify-center items-center`}>
            <OrdersStatsWidget isHubLevelViewActive={true} />
          </div>
        </div>
        <div className="w-full h-px min-h-1 bg-primary"></div>

        <div
          style={{ width: 'calc(100% - 30px)' }}
          className={`flex flex-col flex-1 mb-4`}
        >
          <EmployeeLevel hubSlug={selectedHub.slug} />
        </div>
      </div>
    </>
  );
};

export default LiveSteering;
