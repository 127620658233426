import { Icon, ProgressBar, ProgressBarProps } from '@hubportal/components';

interface CheckProgressProps {
  title: string;
  completedPercentage: number;
  completedChecksCount: number;
  allChecksCount: number;
  progressColor: string;
}

const CheckProgress: React.FC<CheckProgressProps> = ({
  title,
  completedPercentage,
  completedChecksCount,
  allChecksCount,
  progressColor,
}: CheckProgressProps) => {
  return (
    <div
      className={`p-[0.56vw] h-full subheading flex flex-col items-start ${
        completedPercentage === 100 ? 'opacity-50' : ''
      }`}
    >
      <h3 className="w-[3vw]">{title}</h3>
      <div className="w-full py-[0.42vw]">
        <ProgressBar
          width={completedPercentage as ProgressBarProps['width']}
          color={progressColor}
          height={1}
          backgroundColor="bg-[#3A4045]"
        />
      </div>
      <div className="flex justify-between items-center ">
        <span>
          {completedChecksCount}/{allChecksCount}
        </span>
        {completedPercentage === 100 && (
          <span className="text-[#008040] ml-[0.56vw]">
            <Icon type="verification" className="w-[1.11vw] h-[1.11vw]" />
          </span>
        )}
      </div>
    </div>
  );
};

export default CheckProgress;
