import { getInstance } from '@eppo/js-client-sdk';

export const FEATURE_FLAGS = {
  REMOVE_CUSTOMER_PHONE: 'of-dashboard-hide-phone',
  RIDER_WIDGET_IS_NOT_ACTIVE: 'of-rider-widget-enabled',
};

export const isRemovePhoneEnabled = (slug: string): boolean => {
  const eppoClient = getInstance();

  const removePhoneFlag = eppoClient.getBooleanAssignment(
    FEATURE_FLAGS.REMOVE_CUSTOMER_PHONE,
    slug,
    { hub_slug: slug },
    false
  );
  return removePhoneFlag;
};

export const isRiderWidgetEnabled = (slug: string): boolean => {
  const eppoClient = getInstance();

  const riderWidgetFlag = eppoClient.getBooleanAssignment(
    FEATURE_FLAGS.RIDER_WIDGET_IS_NOT_ACTIVE,
    slug,
    { hub_slug: slug },
    false
  );
  return riderWidgetFlag;
};
