import '@hubportal/components/index.css';
import './tailwind.scss';

import i18n from 'i18next';
import sdk, { LocaleT } from '@hubportal/sdk';
import { StrictMode, useEffect, useSyncExternalStore } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';

import { config } from 'i18n/i18n';
import UserContext from 'context/userContext';
import { App } from './App';

i18n.use(initReactI18next).init(config);

export default function Root({ authClient }: PropsWithAuth0Client): JSX.Element {
  const { i18n } = useTranslation();

  const hub = useSyncExternalStore(sdk.onHubChange, () => sdk.getHub());
  const locale = useSyncExternalStore(sdk.onLocaleChange, () => sdk.getLocale());

  const country = useSyncExternalStore(sdk.onCountryChange, () => sdk.getCountry());

  useEffect(() => {
    locale && typeof locale === 'string' && i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    i18n.changeLanguage(locale as LocaleT);
  }, [i18n, locale]);

  return (
    <StrictMode>
      <UserContext.Provider
        value={{
          country,
          setCountry: sdk.setCountry,
          selectedHub: hub,
          setSelectedHub: sdk.setHub,
          locale,
          setLocale: sdk.setLocale,
        }}
      >
        <App authClient={authClient} />
      </UserContext.Provider>
    </StrictMode>
  );
}
