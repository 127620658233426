type Props = {
  label: string;
  borderColor: string;
  textColor: string;
  isHubLevelViewActive: boolean;
};

export const OutlinedBadge = ({ label, borderColor, textColor, isHubLevelViewActive }: Props) => (
  <div
    className={`select-none rounded-full border bg-transparent font-medium ${borderColor} ${textColor} ${
      isHubLevelViewActive ? 'text-fluid-sm px-[0.56vw] py-[0.28vw]' : 'px-2 py-1 text-sm'
    }`}
  >
    {label}
  </div>
);
