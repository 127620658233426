import { useEffect, useState } from 'react';
import { init } from '@eppo/js-client-sdk';

const useFlags = () => {
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    const initEppoSdk = async (): Promise<void> => {
      await init({
        apiKey: process.env.EPPO_SDK_KEY || '',
        assignmentLogger: {
          logAssignment() {},
        },
      });
      setReady(true);
    };
    if (!isReady) {
      initEppoSdk();
    }
  }, [isReady]);
};

export default useFlags;
