import { Modal } from '@hubportal/components';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ManualWarning = ({
  item: warning,
  border,
}: {
  item: ManualWarning;
  border?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <span className="contents">
        <div>{warning.user_id}</div>
        <div className="flex flex-col gap-4">
          <div>{warning.reason_title}</div>
          <div
            className="detail-l text-pink-500 underline cursor-pointer"
            onClick={() => setIsExpanded(true)}
          >
            {t('details')}
          </div>
        </div>
        {border && (
          <div className="col-span-2 border-b border-solid border-secondary"></div>
        )}
      </span>
      {isExpanded && (
        <Modal open={isExpanded} onClose={() => setIsExpanded(false)}>
          <div className="flex flex-col gap-4 items-start min-h-0">
            <div className="header-s pb-4 text-left w-full border-b border-solid border-secondary">
              {t('manual_warning')} {warning.external_code}
            </div>
            <div className="grid grid-cols-2 text-left gap-x-4 gap-y-2 text-s">
              <div className="text-[#BFBFBF]">{t('external_code')}</div>
              <div>{warning.external_code}</div>

              <div className="text-[#BFBFBF]">{t('reason_code')}</div>
              <div>{warning.reason_code}</div>

              <div className="text-[#BFBFBF]">{t('reason_title')}</div>
              <div>{warning.reason_title}</div>

              <div className="text-[#BFBFBF]">{t('verbal_warning_date')}</div>
              <div>
                {warning.verbal_warning_date
                  ? format(new Date(warning.verbal_warning_date), 'dd.MM.yyyy')
                  : '-'}
              </div>

              <div className="text-[#BFBFBF]">{t('written_warning_date')}</div>
              <div>
                {warning.written_warning_date
                  ? format(new Date(warning.written_warning_date), 'dd.MM.yyyy')
                  : '-'}
              </div>

              <div className="text-[#BFBFBF]">{t('final_warning_date')}</div>
              <div>
                {warning.final_warning_date
                  ? format(new Date(warning.final_warning_date), 'dd.MM.yyyy')
                  : '-'}
              </div>

              <div className="text-[#BFBFBF]">{t('in_probation')}</div>
              <div>{warning.in_probation ? t('yes') : t('no')}</div>

              <div className="text-[#BFBFBF]">{t('comments')}</div>
              <div>{warning.comments}</div>

              <div className="text-[#BFBFBF]">{t('created_by')}</div>
              <div>{warning.user_id}</div>

              <div className="text-[#BFBFBF]">{t('created_at')}</div>
              <div>
                {warning.created_at
                  ? format(new Date(warning.created_at), 'dd.MM.yyyy')
                  : '-'}
              </div>

              <div className="text-[#BFBFBF]">{t('updated_at')}</div>
              <div>
                {warning.updated_at
                  ? format(new Date(warning.updated_at), 'dd.MM.yyyy')
                  : '-'}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
