import { Icon } from '@hubportal/components';
import TooltipSectionItem from '.';

const ridingTooltipSections = [
  {
    title: (
      <div className="flex text-flinkGray-light">
        <Icon type="bicycle" className="w-[1.67vw] h-[1.67vw]" />
        <span className="ml-[0.56vw]">Riding Metrics</span>
      </div>
    ),
    answer: (
      <div>
        <div className="mt-[0.83vw] font-bold headline-title">Statuses</div>
        <TooltipSectionItem
          titleKey="hub_metrics.status.online"
          descriptionKey="learn_more.riding_metrics.status.online.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.returning"
          descriptionKey="learn_more.riding_metrics.status.returning.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.busy"
          descriptionKey="learn_more.riding_metrics.status.busy.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.inactive"
          descriptionKey="learn_more.riding_metrics.status.inactive.description"
        />
        <div className="w-full h-px min-h-1 bg-secondary mt-6" />
        <div className="headline-title mt-[0.83vw] font-bold">Metrics</div>
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.deliveries_per_hour.title"
          descriptionKey="learn_more.riding_metrics.deliveries_per_hour.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.acceptance_rate.title"
          descriptionKey="learn_more.riding_metrics.acceptance_rate.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.avg_time_at_customer.title"
          descriptionKey="learn_more.riding_metrics.avg_time_at_customer.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.trip_on_time_compliance.title"
          descriptionKey="learn_more.riding_metrics.trip_on_time_compliance.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.unaccounted_time_offline.title"
          descriptionKey="learn_more.riding_metrics.unaccounted_time_offline.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.riding_metrics.avg_rider_preparation_time_in_seconds.title"
          descriptionKey="learn_more.riding_metrics.avg_rider_preparation_time_in_seconds.description"
        />
      </div>
    ),
  },
];

export default ridingTooltipSections;
